/**
 * Created by john on 23/9/16.
 */
'use strict';

/* exported
 provincesCtrl
  */

function provincesCtrl($scope, $state, provinces, Restangular, $location, $uibModal, user) {
  $scope.provinces = provinces;
  $scope.filters = {};
  $scope.isEditProvinceRole = user.scope.includes('data-entry');
  const search = $location.search();

  // On init if name is filtered
  if (search.name) {
    $scope.filters.name = search.name;
  }
  if (search.country) {
    $scope.filters.country = search.country;
  }
  /**
   * Paging
   */
  // On init if page is above 1
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.provinces.length;
  $scope.pageChanged = function () {
    const limit = 20;
    const offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;
    $scope.filters.populateCities = true;

    $scope.provinces.getList($scope.filters).then(function (provinces) {
      $scope.provinces = provinces;
    });
  };

  $scope.$watch('currentPage', function (page) {
    const search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });
  $scope.deleteProvince = function (provinceId) {
    $uibModal.open({
      templateUrl: 'views/are-you-sure-modal.html',
      controller: 'AreYouSureCtrl',
      resolve: {
        text: null,
        title: [function () { return 'Are you sure?'; }]
      }
    })
      .result
      .then(function (response) {
        if (response) {
          Restangular.one(`v2/provinces/${provinceId}`).remove().then(function (deleteRes) {
            $scope.$emit("notify", {
              type: "success",
              title: "Delete province",
              message: "Province deleted successfully",
            });
            $state.reload();
          })
        }
      })
  }
  /**
   * Filters
   */

  $scope.$on('filters.name', function (event, data) {
    $scope.filters.name = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.withPagination = true;
    $scope.filters.populateCities = true;

    $scope.provinces.getList($scope.filters).then(function (provinces) {

      $scope.currentPage = 1;
      $scope.provinces = provinces;
      $scope.totalItems = $scope.provinces.meta.total;
    });
  });
  $scope.$on('filters.country', function (event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;
    $scope.filters.withPagination = true;
    $scope.filters.populateCities = true;

    $scope.provinces.getList($scope.filters).then(function (provinces) {

      $scope.currentPage = 1;
      $scope.provinces = provinces;
      $scope.totalItems = $scope.provinces.meta.total;
    });
  });
}


'use strict';

/* exported
  menuProvincesCtrl
 */
function menuProvincesCtrl($scope, $rootScope, $location) {
  $scope.filters = {};
  // On init if country is selected display it
  const search = $location.search();

  if (search.countryName) {
    $scope.filters.name = search.name;
  }

  $scope.$watch('filters.name', function(name) {
    $rootScope.$broadcast('filters.name', name);

    const search = $location.search();
    search.name = name;
    $location.search(search);
  });
  $scope.$watch('filters.country', function(country) {
    const countryCode = country ? country.code : '';
    $rootScope.$broadcast('filters.country', countryCode);

    const search = $location.search();
    search.country = countryCode;
    $location.search(search);
  });
  $scope.reset = function() {
    $scope.filters = {};
    $location.search({});
  };
}


'use strict';

/* exported
 provinceViewCtrl
 */

function provinceViewCtrl($scope, province, appConfig, user) {
  $scope.province = province;
  $scope.provinceCities = (province.cities || []).map((city) => city.name);
  $scope.isEditProvinceRole = user.scope.includes('data-entry');
}


"use strict";

/* exported
 provinceEditCtrl
 */

function provinceEditCtrl($scope, province, Restangular, $state) {
  $scope.province = province || {};
  $scope.province.cities = $scope.province.cities || [];
  const input = document.getElementById("name-input");
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: ["geocode", "establishment"],
  };

  $scope.selectedCity = null;
  $scope.lastCity = {};

  $scope.addCity = function () {
    if (!$scope.selectedCity.name || $scope.selectedCity.name === '') {
      return;
    }
    $scope.province.cities.push($scope.selectedCity);
    $scope.selectedCity = null;
  };

  $scope.$watch('selectedCity', function (value, oldValue) {
    if (value) {
      $scope.lastCity = value;
    }
  }, true);

  $scope.removeCity = function (index) {
    $scope.province.cities.splice(index, 1);
  };
  const autocomplete = new google.maps.places.Autocomplete(input, options);
  autocomplete.unbind("bounds");
  autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });

  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();
    $scope.province.name = place.name
  });
  $scope.$watch('province.country', function (country) {
    if ($scope.province && $scope.province.country && country.common) {
      $scope.province.country = country.common.name;
      $scope.province.countryCode = country.iso;
    }
  });
  $scope.save = function () {
    $scope.globalError = null;

    if ($scope.provinceForm.$invalid) {
      $scope.provinceForm.name.$setTouched();
      // $scope.provinceForm.slug.$setTouched();
      $scope.provinceForm.country.$setTouched();
      $scope.provinceForm.description.$setTouched();

      return;
    }
    $scope.province.countryCode = $scope.province.country.code;
    $scope.province.country = $scope.province.country.name.common;

    $scope.province.cities = [...$scope.province.cities, $scope.lastCity];
    $scope.selectedCity = null;
    const promise = $scope.province._id
      ? $scope.province.put()
      : Restangular.all("v2/provinces").post($scope.province);

    promise
      .then(function (province) {
        $scope.$emit("notify", {
          type: "success",
          title: "Create province",
          message: "Province created with success",
        });
        $scope.province = province;
        $state.go("root.editProvince", { provinceId: province._id });
      })
      .catch(function (err) {
        $scope.$emit("notify", {
          type: "error",
          title: "Create province",
          message: "Failed to create province",
        });

        $scope.globalError = err.data.message;
      });
  };
}
